import React from "react";

export default function Loader() {
  const items = Array.from({ length: 21 }, (_, i) => i);
  return (
    <section className="flex items-center justify-center p-8 mt-56">
      {items.map((i) => (
        <div key={i} className="absolute item" style={{ "--i": i }}></div>
      ))}
    </section>
  );
}
