import React, { useState } from "react";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);

  setTimeout(() => {
    setIsLoad(false);
  }, 1000);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <section className="p-6 rounded-lg shadow-2xl text-black bg-slate-400 m-3 text-lg">
        <h1 className="text-3xl font-bold text-center">About Us</h1>
        <p className="my-3">
          Welcome to <b>Nature Wise Garden!</b>
        </p>
        <p className="my-3">
          At Nature Wise Garden, we are dedicated to promoting sustainable
          gardening practices and providing resources to help you create a
          beautiful and eco-friendly garden. Our mission is to support gardeners
          of all levels in cultivating lush, thriving spaces that benefit both
          the environment and the community.
        </p>

        <h6 className="font-bold">Our Story</h6>
        <p className="my-2">
          Founded in 2024, Nature Wise Garden was established with a vision to
          blend nature’s beauty with sustainable gardening techniques. We are
          passionate about helping individuals create gardens that are not only
          aesthetically pleasing but also environmentally responsible.
        </p>

        <h6 className="font-bold">Our Team</h6>
        <p className="my-2">
          Our team consists of experienced horticulturists, gardeners, and
          environmentalists who are committed to sharing their expertise and
          love for gardening. We work together to provide valuable resources and
          support to help you achieve your gardening goals.
        </p>

        <h6 className="font-bold">Our Services</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Gardening Resources:</b> Access a wealth of information on
            sustainable gardening practices, plant care, and garden design.
          </li>
          <li className="my-2">
            <b>Eco-Friendly Products:</b> Discover a range of environmentally
            friendly gardening products and tools.
          </li>
          <li className="my-2">
            <b>Community Support:</b> Join our community of garden enthusiasts
            to share tips, ask questions, and connect with others who share your
            passion for gardening.
          </li>
        </ul>

        <p className="my-2">
          Thank you for choosing Nature Wise Garden. We are excited to be a part
          of your gardening journey and to support you in creating a beautiful
          and sustainable garden.
        </p>
      </section>
    </>
  );
}
