import React from "react";
import { Link } from "react-router-dom";

export default function GameCard({ data }) {
  return (
    <Link to={`/single?slug=${data.slug}`}>
      <div className="relative bg-gradient-to-br from-amber-500 to-pink-800 rounded-lg flex items-center justify-center overflow-hidden transition-transform duration-600 ease-in-out hover:-rotate-6 hover:scale-110 hover:shadow-lg sm:rotate-0 sm:scale-100">
        <img
          src={data.logo}
          alt={data.title}
          className="w-full h-64 object-cover"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -rotate-45 w-full h-full p-5 bg-gradient-to-r from-cyan-400 via-sky-300 to-indigo-300 opacity-0 transition-opacity duration-600 ease-in-out hover:opacity-100 hover:rotate-0 flex justify-center items-center sm:rotate-0">
          <p className="font-bold text-2xl">{data.title}</p>
        </div>
      </div>
    </Link>
  );
}
